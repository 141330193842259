<template>
  <b-card>
    <div class="fama-table">
      <div v-if="dataLoading" class="spinner-border m-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-else-if="error.length !== 0" class="m-auto">
        {{ error }}
      </div>
      <div v-else-if="error.length === 0" style="width: 100%">
        <b-button
          class="mb-2"
          type="submit"
          variant="primary"
          @click="showCreateSiteUser"
        >
          Create Site User
        </b-button>
        <span class="mx-1"></span>
        <b-button
          class="mb-2"
          type="submit"
          variant="primary"
          @click="showCreateFirmUser"
        >
          Create Company User
        </b-button>
        <DxDataGrid
          id="userTable"
          ref="data-grid"
          width="100%"
          :show-borders="true"
          :data-source="data"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          column-resizing-mode="widget"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :hover-state-enabled="true"
          :selection="{ mode: 'single' }"
          key-expr="id"
          @row-updated="onUpdated"
          @row-updating="onUpdating"
          @exporting="onExporting"
        >
          <DxExport :enabled="true" />
          <DxColumn data-field="username" caption="Username" />
          <DxColumn data-field="firstname" caption="Name" />
          <DxColumn data-field="lastname" caption="Surname" />
          <DxColumn data-field="site_id" caption="Site">
            <DxLookup
              :data-source="allSites"
              display-expr="name"
              value-expr="id"
            />
          </DxColumn>
          <DxColumn data-field="firm.name" caption="Firm" />
          <DxEditing :allow-updating="true" mode="raw" />
          <DxSearchPanel :visible="true" />
          <DxColumnFixing :enabled="true" />
          <DxColumnChooser :enabled="true" />
          <DxScrolling mode="standard" />
          <DxGrouping :context-menu-enabled="true" />
          <DxGroupPanel :visible="true" />
        </DxDataGrid>
      </div>
      <DxPopup
        :visible="popupVisible"
        :close-on-outside-click="false"
        :show-close-button="false"
        :show-title="true"
        :width="400"
        :height="650"
        title="Create Company User"
      >
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">Customer</div>
            <div class="dx-field-value">
              <DxSelectBox
                :data-source="customers"
                :display-expr="gridBoxDisplayExpr"
                value-expr="id"
                @value-changed="onCustomerValueChanged"
              />
            </div>
          </div>
          <div v-if="isSelectCustomer" class="dx-field">
            <DxForm
              id="form"
              :col-count="1"
              :on-content-ready="validateForm"
              :form-data="userData"
            >
              <DxItem
                caption="Username"
                data-field="username"
                :validation-rules="validationRules.username"
              />
              <DxItem
                caption="Name"
                data-field="name"
                :validation-rules="validationRules.name"
              />
              <DxItem
                caption="Surname"
                data-field="lastname"
                :validation-rules="validationRules.lastname"
              />
              <DxItem
                caption="Password"
                data-field="password"
                :validation-rules="validationRules.password"
              />
              <DxItem
                :editor-options="{
                  dataSource: firms,
                  value: '',
                  displayExpr: 'name',
                  valueExpr: 'id',
                }"
                caption="Firm"
                data-field="firm_id"
                editor-type="dxSelectBox"

              />
            </DxForm>
          </div>
        </div>
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="before"
          :options="saveButtonOptions"
        />
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          :options="closeButtonOptions"
        />
      </DxPopup>
    </div>
  </b-card>
</template>

<script>
import axios from "@axios";
import "devextreme-vue/tag-box";
import { BCard, BButton } from "bootstrap-vue";
import {
  DxDataGrid,
  DxLookup,
  DxExport,
  DxColumn,
  DxEditing,
  DxScrolling,
  DxColumnFixing,
  DxSearchPanel,
  DxColumnChooser,
  DxGroupPanel,
  DxGrouping
} from "devextreme-vue/data-grid";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import DxSelectBox from "devextreme-vue/select-box";
import { DxForm, DxItem } from "devextreme-vue/form";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

export default {
  components: {
    DxSelectBox,
    DxLookup,
    DxExport,
    DxForm,
    DxItem,
    DxToolbarItem,
    DxPopup,
    BButton,
    DxColumn,
    DxColumnFixing,
    DxDataGrid,
    DxEditing,
    DxScrolling,
    DxSearchPanel,
    BCard,
    DxColumnChooser,
    DxGroupPanel,
    DxGrouping
  },
  data() {
    return {
      data: [],
      sites: [],
      firms: [],
      customers: [],
      userData: {},
      dataLoading: true,
      isFirmUser: false,
      popupVisible: false,
      isSelectCustomer: false,
      error: "",
      allSites: [],
      customerId: "",
      site_id: "",
      validationRules: {
        name: [{ type: "required", message: "Name is required." }],
        username: [{ type: "required", message: "Username is required." }],
        password: [{ type: "required", message: "Password is required." }],
        lastname: [{ type: "required", message: "Surname is required." }],
        customer: [{ type: "required", message: "Customer is required." }]
      },
      gridBoxDisplayExpr(item) {
        return item && `${item.customer_no} - ${item.lastname}`;
      },
      saveButtonOptions: {
        icon: "save",
        text: "Save",
        onClick: () => {
          const { name, username, password, lastname } = this.userData;
          if (!name || !username || !password || !lastname) {
            this.showToast({
              variant: "error",
              title: "Error",
              text: "Please, fill all items"
            });
            return;
          } else if (lastname.length != 1) {
            this.showToast({
              variant: "error",
              title: "Error",
              text: "Surname must have a one character"
            });
            return;
          }
          this.isFirmUser
            ? this.postCreateFirmUser()
            : this.postCreateSiteUser();
        }
      },
      closeButtonOptions: {
        icon: "close",
        text: "Close",
        onClick: () => {
          this.popupVisible = false;
          this.userData = {};
        }
      }
    };
  },
  mounted() {
    this.getAllSites();
    this.refreshData();
    this.getCustomer();
    this.getFirm();
  },
  methods: {
    validateForm(e) {
      e.component.validate();
    },
    showCreateSiteUser() {
      this.popupVisible = true;
      this.isFirmUser = false;
    },
    showCreateFirmUser() {
      this.popupVisible = true;
      this.isFirmUser = true;
    },
    onUpdated() {
      this.showToast({
        variant: "success",
        title: "Success",
        text: "Data has been updated"
      });
    },
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(`Fama-User`);
      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function(options) {
          const excelCell = options;
          excelCell.font = { name: "Arial", size: 12 };
          excelCell.alignment = { horizontal: "left" };
        }
      }).then(function(a) {
        console.log(a);
        // const tableNamee = this.tableName;
        workbook.xlsx.writeBuffer().then(function(buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "Fama-Users.xlsx"
          );
        });
      });
      e.cancel = true;
    },
    async onUpdating(e) {
      const data = {
        ...e.oldData,
        ...e.newData
      };
      const postObject = this.getPostObject(data);
      e.cancel = this.isCancelled(`/edit-site/${this.data.id}`, data);
    },
    onCustomerValueChanged(event) {
      this.isSelectCustomer = true;
      if (event.value !== "") {
        this.getSite(event.value);
      }
    },
    onSiteValueChanged(event) {

      if (event.value !== "") {
        this.getFirmBySite(event.value);
      }
    },
    async getAllSites() {
      this.allSites = (await axios.get("/list-site")).data?.result || [];
      console.log(this.allSites);
    },
    refreshData() {
      this.dataLoading = true;
      this.error = "";
      axios
        .get("/list-user")
        .then((response) => {
          if (response.status === 200) {
            this.data = response?.data?.result || [];
            this.dataLoading = false;
          } else {
            this.error = response?.data?.message || "Unexpected API error";
            this.dataLoading = false;
          }
        })
        .catch((e) => {
          this.error = e?.response?.data?.message || e?.message;
          this.dataLoading = false;
        });
    },
    getSite(customerId) {
      this.customerId = customerId;
      axios
        .get(`/list-customer-site/${customerId}`)
        .then((response) => {
          if (response.status === 200) {
            this.sites = response?.data?.result || [];
          } else {
            this.error = response?.data?.message || "Unexpected API error";
          }
        })
        .catch((e) => {
          this.error = e?.response?.data?.message || e?.message;
        });
    },
    getFirmBySite(site_id) {

      this.site_id = site_id;
      if(site_id != null){
        axios
          .get(`/list-customer-firm?site_id=${site_id}`)
          .then((response) => {
            if (response.status === 200) {
              let q = [];
              if (response?.data?.result.length > 0) {
                response?.data?.result.forEach(a => {
                  a.firms.forEach(p => {
                    q.push(p);
                  });
                });
              }
              this.firms = q;
            } else {
              this.error = response?.data?.message || "Unexpected API error";
            }
          })
          .catch((e) => {
            this.error = e?.response?.data?.message || e?.message;
          });
      }

    },
    getCustomer() {
      axios
        .get("/list-customer")
        .then((response) => {
          if (response.status === 200) {
            this.customers = response?.data?.result || [];
          } else {
            this.error = response?.data?.message || "Unexpected API error";
          }
        })
        .catch((e) => {
          this.error = e?.response?.data?.message || e?.message;
        });
    },
    getFirm() {
      axios
        .get("/list-firm")
        .then((response) => {
          if (response.status === 200) {
            this.firms = response?.data?.result || [];
          } else {
            this.error = response?.data?.message || "Unexpected API error";
          }
        })
        .catch((e) => {
          this.error = e?.response?.data?.message || e?.message;
        });
    },
    showToast({ variant = "success", title, text, icon = "InfoIcon" }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon,
          variant
        }
      });
    },
    async isCancelled(path, data) {
      let response;
      try {
        response = await axios.post(path, data);
      } catch (e) {
        this.showToast({
          title: "Error",
          variant: "danger",
          text: e?.response?.data?.message || e?.message
        });
        return true;
      }
      if (response.status === 200) return false;
      else {
        this.showToast({
          title: "Error",
          variant: "danger",
          text: response?.data?.message
        });
        return true;
      }
    },
    async postCreateSiteUser() {
      let response;
      const {
        name,
        lastname,
        username,
        site: site_id,
        password
      } = this.userData;
      try {
        response = await axios.post("/create-user", {
          name,
          lastname,
          username,
          password,
          role: "admin",
          customer_id: this.customerId,
          site_id
        });
        if (response.status === 200) {
          this.showToast({
            variant: "success",
            title: "Success",
            text: "New data has been inserted"
          });
          this.popupVisible = false;
          this.siteData = {};
          this.refreshData();
        } else {
          this.showToast({
            title: "Error",
            variant: "danger",
            text: e?.response?.data?.message || e?.message
          });
          this.refreshData();
        }
      } catch (e) {
        this.showToast({
          title: "Error",
          variant: "danger",
          text: e?.response?.data?.message || e?.message
        });
      }
    },
    async postCreateFirmUser() {
      console.log("********");
      let response;
      const {
        name,
        lastname,
        username,
        firm_id,
        site_id,
        password
      } = this.userData;
      try {
        response = await axios.post("/create-firm-user", {
          name,
          lastname,
          username,
          password,
          role: "admin",
          customer_id: this.customerId,
          firm_id,
          site_id
        });
        if (response.status === 200) {
          this.showToast({
            variant: "success",
            title: "Success",
            text: "New data has been inserted"
          });
          this.popupVisible = false;
          this.siteData = {};
          this.refreshData();
        } else {
          this.showToast({
            title: "Error",
            variant: "danger",
            text: e?.response?.data?.message || e?.message
          });
          this.refreshData();
        }
      } catch (e) {
        this.showToast({
          title: "Error",
          variant: "danger",
          text: e?.response?.data?.message || e?.message
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import './tables.scss';
</style>
